var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "healthCheckup" },
    [
      _c(
        "Card",
        {
          staticStyle: {
            "margin-top": "10px",
            height: "calc(100% - 300px)",
            overflow: "auto",
          },
        },
        [
          _c("Alert", { staticClass: "ivu-alert-left" }, [
            _vm._v(" 体检项目 "),
            _c(
              "span",
              { staticStyle: { display: "inline-block" } },
              [
                (_vm.personInfo.isPass == 1 &&
                  !_vm.isUpdateUnitNameGroup &&
                  _vm.showSelectButton) ||
                (_vm.isUpdateUnitNameGroup && _vm.showSelectButton)
                  ? _c(
                      "Button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "md-add",
                          size: "small",
                        },
                        on: { click: _vm.checkCombo },
                      },
                      [_vm._v("套餐选择")]
                    )
                  : _vm._e(),
                (!_vm.isUpdateUnitNameGroup && _vm.showSelectButton) ||
                (_vm.isUpdateUnitNameGroup && _vm.showSelectButton)
                  ? _c(
                      "Button",
                      {
                        attrs: {
                          type: "primary",
                          icon: "md-add",
                          size: "small",
                        },
                        on: { click: _vm.addTPortfolioProject },
                      },
                      [_vm._v("选检项目 ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c("Table", {
            ref: "table",
            attrs: {
              loading: _vm.tableLoading,
              border: "",
              columns: _vm.columns,
              sortable: "custom",
              "show-summary": "",
              "summary-method": _vm.handleSummary,
              data: _vm.projectData,
              "max-height": "380",
            },
            scopedSlots: _vm._u([
              {
                key: "action",
                fn: function ({ row, index }) {
                  return [
                    _vm.personInfo.isPass == 1 &&
                    !_vm.isUpdateUnitNameGroup &&
                    (row.projectType == 2 ||
                      (_vm.physicalType != "职业体检" &&
                        _vm.physicalType != "放射体检"))
                      ? _c(
                          "Button",
                          {
                            attrs: {
                              type: "error",
                              loading: _vm.delLoading,
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteData(row, index)
                              },
                            },
                          },
                          [
                            _c("Icon", { attrs: { type: "md-trash" } }),
                            _vm._v(" 删除 "),
                          ],
                          1
                        )
                      : _vm.personInfo.isPass == 2
                      ? _c(
                          "Button",
                          {
                            attrs: { type: "error", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.deleteData(row, index)
                              },
                            },
                          },
                          [_vm._v("删除项目")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          !_vm.typeStatus
            ? _c(
                "Form",
                { attrs: { "label-width": 100 } },
                [
                  _c(
                    "Row",
                    { staticStyle: { "margin-top": "16px" } },
                    [
                      _c(
                        "Col",
                        {
                          attrs: {
                            xs: { span: 24 },
                            md: { span: 11, offset: 1 },
                            lg: { span: 7, offset: 1 },
                            xl: { span: 5, offset: 1 },
                          },
                        },
                        [
                          _c(
                            "FormItem",
                            { attrs: { label: "必检项折扣：" } },
                            [
                              _c("InputNumber", {
                                attrs: { min: 1, max: 100 },
                                on: { "on-change": _vm.onDiscountChange },
                                model: {
                                  value: _vm.groupData.discount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.groupData, "discount", $$v)
                                  },
                                  expression: "groupData.discount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        {
                          attrs: {
                            xs: { span: 24 },
                            md: { span: 11, offset: 1 },
                            lg: { span: 7, offset: 1 },
                            xl: { span: 5, offset: 1 },
                          },
                        },
                        [
                          _c(
                            "FormItem",
                            { attrs: { label: "选检项折扣：" } },
                            [
                              _c("InputNumber", {
                                attrs: { min: 1, max: 100 },
                                on: { "on-change": _vm.onAddDiscountChange },
                                model: {
                                  value: _vm.groupData.addDiscount,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.groupData, "addDiscount", $$v)
                                  },
                                  expression: "groupData.addDiscount",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "Col",
                        {
                          staticStyle: { display: "flex" },
                          attrs: {
                            xs: { span: 24 },
                            md: { span: 11, offset: 1 },
                            lg: { span: 7, offset: 1 },
                            xl: { span: 5, offset: 1 },
                          },
                        },
                        [
                          _c(
                            "FormItem",
                            { attrs: { label: "优惠价格：" } },
                            [
                              _c("InputNumber", {
                                attrs: { min: 0 },
                                model: {
                                  value: _vm.groupData.discountPrice,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.groupData,
                                      "discountPrice",
                                      $$v
                                    )
                                  },
                                  expression: "groupData.discountPrice",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "ivu-alert ivu-alert-info ivu-alert-with-icon",
              staticStyle: { "margin-top": "16px" },
            },
            [
              _c(
                "div",
                { staticStyle: { "font-size": "16px", margin: "5px 0" } },
                [
                  _c("span", { staticClass: "ivu-alert-message" }, [
                    _vm._v(" 操作说明 "),
                  ]),
                ]
              ),
              _c("div", { staticClass: "explain" }, [
                _c("div", [
                  _c("span", { staticClass: "ivu-alert-message" }, [
                    _vm._v(" 第一步： "),
                  ]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "ivu-alert-message" }, [
                    _vm._v(" 点击新增 "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "explain" }, [
                _c("div", [
                  _c("span", { staticClass: "ivu-alert-message" }, [
                    _vm._v(" 第二步： "),
                  ]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "ivu-alert-message" }, [
                    _vm._v(" 填写信息（填写体检人员信息和选择体检项目） "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "explain" }, [
                _c("div", [
                  _c("span", { staticClass: "ivu-alert-message" }, [
                    _vm._v(" 第三步： "),
                  ]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "ivu-alert-message" }, [
                    _vm._v(" 点击保存（保存体检人员和体检项目信息） "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "explain" }, [
                _c("div", [
                  _c("span", { staticClass: "ivu-alert-message" }, [
                    _vm._v(" 第四步： "),
                  ]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "ivu-alert-message" }, [
                    _vm._v(" 采集人像（采集体检人员头像） "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "explain" }, [
                _c("div", [
                  _c("span", { staticClass: "ivu-alert-message" }, [
                    _vm._v(" 第五步： "),
                  ]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "ivu-alert-message" }, [
                    _vm._v(" 确认登记 "),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "explain" }, [
                _c("div", [
                  _c("span", { staticClass: "ivu-alert-message" }, [
                    _vm._v(" 第六步： "),
                  ]),
                ]),
                _c("div", [
                  _c("span", { staticClass: "ivu-alert-message" }, [
                    _vm._v(" 打印导检单（体检人员可以根据导检单进行体检） "),
                  ]),
                ]),
              ]),
              _vm.physicalType == "健康体检"
                ? _c("div", { staticClass: "explain" }, [
                    _c("div", [
                      _c("span", { staticClass: "ivu-alert-message" }, [
                        _vm._v(" 第七步： "),
                      ]),
                    ]),
                    _c("div", [
                      _c("span", { staticClass: "ivu-alert-message" }, [
                        _vm._v(" 确认缴费 "),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _c("div", [
                _vm.physicalType == "健康体检"
                  ? _c("span", { staticClass: "ivu-alert-message" }, [
                      _vm._v(" 或执行第四、五、六、七步 "),
                    ])
                  : _c("span", { staticClass: "ivu-alert-message" }, [
                      _vm._v(" 或执行第四、五、六步 "),
                    ]),
              ]),
            ]
          ),
        ],
        1
      ),
      _c("choose-portfolio-project", {
        attrs: { checkedList: _vm.choosePortfolioProjectData },
        on: { handleSearch: _vm.setTableData },
        model: {
          value: _vm.choosePortfolioProjectShow,
          callback: function ($$v) {
            _vm.choosePortfolioProjectShow = $$v
          },
          expression: "choosePortfolioProjectShow",
        },
      }),
      _c("choose-combo", {
        attrs: { checkedList: _vm.comboIdList, physicalType: _vm.physicalType },
        on: { handleSearch: _vm.setComboData },
        model: {
          value: _vm.chooseComboShow,
          callback: function ($$v) {
            _vm.chooseComboShow = $$v
          },
          expression: "chooseComboShow",
        },
      }),
      _c("weixin-drawer", {
        attrs: { modalTitle: "微信订单", comboId: _vm.comboId },
        on: { selCallBack: _vm.selWeixinCallBack },
        model: {
          value: _vm.weixinDrawerShow,
          callback: function ($$v) {
            _vm.weixinDrawerShow = $$v
          },
          expression: "weixinDrawerShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }